export const GOOGLE_MAPS_ENABLED = 'WEB-google-maps-sales-flow';
export const SANITY_SUBSIDY_NAME = 'WEB-sanity-subsidy';
export const NEWSLETTER_CONSENT_FEATURE_NAME = 'WEB-newsletter-consent';
export const LEASING_SUBSIDY_HIDDEN = 'WEB-leasing-subsidy-hidden';
export const ENABLE_CLOUD_DEBUG_TOOL_NAME = 'WEB-enable-cloud-debug-tool';
export const SUPPORT_ARTICLES_ENABLED = 'WEB-support-articles-enabled';
export const GLOBAL_ALERT = 'WEB-global-alert';
export const BATTERY_TOGGLE_IN_SAVINGS = 'WEB-battery-toggle-savings-calc-page';
export const OPEN_DROPDOWN_IN_SAVINGS_INITIALLY =
  'WEB-open-dropdown-savings-initially-calc-page';
export const FREE_TEXT_ADDRESS_SEARCH_ENABLED =
  'free-text-address-search-enabled';
export const SHOW_OR_SIMILAR_STANDARD_PANEL_TEXT =
  'WEB-show-or-similar-standard-panel-text';
export const SHOW_OR_SIMILAR_PREMIUM_PANEL_TEXT =
  'WEB-show-or-similar-premium-panel-text';
export const SHOW_OR_SIMILAR_TIER3_PANEL_TEXT =
  'WEB-show-or-similar-tier3-panel-text';
export const SHOW_OR_SIMILAR_INVERTER_TEXT =
  'WEB-show-or-similar-inverter-text';
export const SHOW_OR_SIMILAR_BATTERY_TEXT = 'WEB-show-or-similar-battery-text';
export const SHOW_OR_SIMILAR_SMART_METER_TEXT =
  'WEB-show-or-similar-smart-meter-text';
export const SHOW_REPAYMENT_PLAN_START_BALANCE =
  'WEB-show-repayment-plan-start-balance';
export const SHOW_REPRESENTATIVE_EXAMPLES_ON_CALC_PAGE =
  'WEB-show-representative-examples-on-calc-page';
export const SHOW_APR_AND_CASH_PRICE_LEASING_HEADER =
  'WEB-show-apr-and-cash-price-leasing-header';
export const SHOW_APR_AND_CASH_PRICE_LEASING_PRICE_SECTION =
  'WEB-show-apr-and-cash-price-leasing-price-section';
export const SHOW_INFO_SECTION_ON_CALC_PAGE =
  'WEB-show-info-section-on-calc-page';
export const SHOW_SOCIAL_PROOF_SECTION_CALC_PAGE =
  'WEB-show-social-proof-section-on-calc-page';
export const SHOW_HERO_LOGOS_STOREFRONT = 'WEB-show-hero-logos-on-storefront';
export const POSTHOG_ENABLED = 'WEB-posthog-enabled';
export const SHOW_STANDALONE_BATTERY_PACKAGE_SUMMARY_V2 =
  'WEB-show-standalone-battery-package-summary-v2';
