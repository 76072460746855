import { EventType, EventUserData } from '../../../types/analytics';
import { Cloud$Address, Cloud$FinancingOption } from '../../../types/cloudApi';
import { Otovo$Config } from '../../../types/otovoweb';

export const qualifiedInterestEvent: EventType = {
  event: 'Solar Qualified Activation',
  eventCategory: 'Solar',
  eventAction: 'Qualified Activation',
  eventLabel: 'Post Activation',
};

export const newInterestEvent: EventType = {
  event: 'Solar Interest',
  eventCategory: 'Solar',
  eventAction: 'Interest',
  eventLabel: 'Lead',
};

export type GetActivationEventArgs = {
  interestId: string;
  financingOption: Cloud$FinancingOption | undefined;
  config: Otovo$Config;
  email: string;
  phoneNumber: string;
  address: Cloud$Address;
  name: string;
};
export function getActivationEvent({
  interestId,
  financingOption,
  config,
  email,
  phoneNumber,
  address,
  name,
}: GetActivationEventArgs): Record<string, string | EventUserData> {
  const { matchedPartner } = config.BU_CONFIG;
  if (
    matchedPartner &&
    matchedPartner.type === 'AFFILIATE' &&
    matchedPartner.config.name === 'Segugio.it' &&
    financingOption
  ) {
    return {
      event: 'purchase',
      value: `${financingOption.total_gross_price}`,
      id: interestId,
    };
  }

  return {
    event: 'Solar Activation',
    eventCategory: 'Solar',
    eventAction: 'Activation',
    eventLabel: 'Lead',
    user_data: {
      email,
      phone_number: phoneNumber,
      address: {
        first_name: name.split(' ')[0],
        last_name: name.split(' ').at(-1),
        street: address.street_address,
        city: address.zip_code_name,
        region: address.municipality_name,
        postal_code: address.zip_code_code,
        country: address.country.toUpperCase(),
      },
    },
  };
}

export const saleEvent: EventType = {
  event: 'Customer Purchase',
  eventCategory: 'Solar',
  eventAction: 'Sale',
  eventLabel: 'Purchase',
};

export const calculationShownEvent: EventType = {
  event: 'Calculation Shown',
  eventCategory: 'Solar',
  eventAction: 'Calculation',
  eventLabel: 'Lead',
};

export const contactMeEvent: EventType = {
  event: 'Solar Activation Form',
  eventCategory: 'Solar',
  eventAction: 'Form',
  eventLabel: 'Lead',
};

export const surfaceSubmittedEvent: EventType = {
  event: 'Surface submitted',
  eventCategory: 'Custom Surface UI',
  eventAction: 'Submit Surface Succeeded',
  eventLabel: 'Custom Surface Submit',
};
